 <template>
   <div>
    <b>Shares</b> 
    
    <div style="display: inline-block; width: 22%; float: right; "> 
		<vue-slider :marks=false min=0 :max="25" v-model="tolerance"></vue-slider> 
	</div>
	<div :title="'Tolerance for share-limits: +'+tolerance+'%'" style="display: inline-block; width: 13%; float: right; margin-left: 2pt; margin-top: 2pt; font-size: 8pt;"> 
		Tol.: {{ tolerance }}%
	</div>
				
		<div style='padding-top: 5pt;'></div>
		
		    <div style="width: 50%; font-weight: bold; font-size: 9pt; text-align: center; display: inline-block; float: top;">
		    Filter
		    </div>   
		    
		    <div style="width: 6%; font-weight: bold;  font-size: 9pt; text-align: center;  padding-left: 8pt; display: inline-block; padding-top:0pt;">   
			Max. 
			</div>   
			<div title="value in percentage" style="margin-left: 22pt; font-weight: bold;  font-size: 9pt; text-align: center;  width: 10%; padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
			Type
			</div>
			<!--
			<div title="add negative share (opposite share)" style="width: 10%; font-weight: bold;  font-size: 9pt; text-align: center;  padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
			Neg
			</div>
			-->
			<div style="width: 15%; padding-left: 5pt; display: inline-block; padding-top:0pt;"> 
		    </div>
        <div style='overflow-y: auto; height: calc(25vH);'>
			<div v-for="(sh,i) in value" style="width: 100%; display: inline-flex; margin: 0;" :key="'sh_'+i">
				<div style="width: 50%; display: inline-flex; float: bottom;">
			    <InventoryFilterEditor  
			             	  	v-model="sh.inventory"
			             	  	:columnName="'inventory'"
			             	  	:parentRecord="sh"
			             	  	:hideBorder="true"
			             	  	:hideTemplates="false"
			             	  	:columnFilter="{column: 'usage', values: [0,2]}"
			             	  	@open="openInvEditor"
			             	  ></InventoryFilterEditor>	
			    </div>   
			    
			    <div style="width: 12%;  padding-left: 1pt; display: inline-block; padding-top:0pt;">   
				<InputFieldS :tabindex="i" v-model="sh.amount"/>
				</div>   
				
				<div title="share based on" style="margin-left: 1pt; height: 22pt; width: 18%; padding-left: 1pt; display: inline-block; padding-top:0pt;"> 
				<select :tabindex="99+i" :style="getAppStyle()+'width:95%; height: 22pt; font-size: 7pt;'" v-model="sh.type" >
					<option v-for="(t,ti) in getShortTypes()" :key="'sht_'+ti" 
					    :selected="ti==sh.type" :value="ti">
						{{ t }}
					</option>
				</select>
				</div>
				
				<div style="width: 18%; float: right; display: inline-block; padding-top:0pt;"> 
				
				<button @click="removeShare(sh)" title="remove" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="close" /></button>
				<button @click="copyShare(sh)" title="copy" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="content-copy" /></button>
				<button v-if="sh.addNegative && sh.type <= 3" :disabled="sh.type > 3" @click="sh.addNegative=false" title="remove negative share" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="lock" /></button>
				<button v-else :disabled="sh.type > 3" @click="sh.addNegative=true" title="add negative (opposite) share" :style="getAppStyle()+'float: right;'" class='button myButtonS'><mdicon  :width="16" name="lock-open-variant-outline" /></button>
				
				</div>
			
			</div>
  		</div>
  </div>
</template>

<script>

import { getAppStyle, isDarkMode, adapt, getBGColor, getBGStyle2, getBGStyle3 } from '@/AppStyle.js';
import WindowCloseBN from '@/components/misc/WindowCloseBN';
import InputField from '@/components/booking/InputField';
import InputFieldS from '@/components/inputElements/InputMoneyNC';
//import InputFieldS from '@/components/booking/InputFieldS';
import Switch from '@/components/Switch';
import vSelect from 'vue-select'
import ClickOutside from 'vue-click-outside';
import InventoryFilterEditor from '@/components/inputElements/InventoryFilterEditor';
export default {
  name: "SHARES",
   components : {
	   //'app-switch': Switch, 
	   InventoryFilterEditor, InputFieldS
  },
  props: {
	  value: Array,
	  shareTypes: [],
	  simple: { type: Boolean, default: true},
	  packed: { type: Boolean, default: false},
  },
  data(){
    return {
    	shares: [],
    	
    	tolerance: null,
    	getAppStyle, getBGStyle2, getBGStyle3,
    	isPacked: this.packed
     }
    },
    
    directives:{
        ClickOutside,
      },
    methods: {
		copyShare(sh)
		{
			this.$emit( 'copyShare', sh)
		},
		
		removeShare(sh)
		{
			this.$emit( 'removeShare', sh)
		},
		setTolerance()
		{
			if (this.tolerance !== null )
			{
				for ( let sh in this.value)
				{
					let share = this.value[sh];
					share.tolerance = this.tolerance;
				}
			}
		},
		getTolerance()
		{
			for ( let sh in this.value)
			{
				let share = this.value[sh];
				this.tolerance = share.tolerance;
				break;
			}
			if (this.tolerance === null )
			{
				this.tolerance = 5;
			}
		},
		changeLabel( shareType)
		{
			if ( shareType.indexOf('Percent') >= 0)
			{
				let s = shareType.replace('Percent','');
				return "% "+s;
			}
			return shareType;
		},
		getShortTypes()
		{
			let newArr = this.shareTypes.map( p=>this.changeLabel(p));
			
			return newArr;
		},
		sort( shares )
		{
			return shares.sort((a,b)=>a.name.localeCompare(b.name))
		},
		openInvEditor( filter, record, columnName, forceOpen)
        {
      	 //alert("GFWE"+JSON.stringify(record)+columnName)
      	 this.$emit( 'openInvEditor', filter, record, columnName, forceOpen);
        }
  },
   updated() {
    
  },
  computed: {
	
  },
  
  created() {
  	 this.getTolerance();
  },
   watch:
   {
	   tolerance: function() { if (this.tolerance !== null ) this.setTolerance(); },
   	   value: function() { this.getTolerance(); this.$emit("input", this.value); }
   }
}
</script>
<style  scoped>
.myButton {
	padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}  
.myButtonS {
    padding: 0px 0px;
    outline: none;
    border-radius: 3px;
    height: 22pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px inset #ccc;
    color: rgb(0, 0, 0);
    margin-top: 0pt;
    margin-right: 2pt;
    margin-bottom: 0pt;
} 
</style>