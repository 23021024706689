<template>
  <div style='margin: 0pt; height: 100%; width:100%;' @contextmenu="mouseRClicked($event)">
 
  <div style='width: 100%;' :style='getAppStyle()'>
     <div style='width: 100%;'>  	  
        <div>
          <slot name="body">
            <div style='float: top; width: 100%;' >
             <div class='transition' :style='getControlStyle()'>
	    		<!-- CONTROLS -->
			    <div style='float: top; width: 100%; vertical-align: middle;' class='smaller'>
			        <!-- FILTERS -->    
				    <div class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>FILTER:</b></span>
			        </div>

				    <span class='dontWrap smallText bold'>Pricegroups:</span>
					<GSelect :options="allPG" v-model="selectedPG" :multiple="true" >  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Timeslots:</span>
					<GSelect :options="allTimeslots" v-model="selectedTimeslots" :multiple="true">  
				    </GSelect>
				    
				    <span class='dontWrap smallText bold'>Grids/Programs:</span>
					<GSelect :options="allGrids" v-model="selectedGrids" :multiple="true" >  
				    </GSelect>

				    <button @click='clearFilter()' :style="getAppStyle()+'font-size: 8pt;'" class='button myButtonSmall minRound'>Remove filter</button>



					<!-- INFO -->    
					
					<div v-show="placement.target" class="BMSection" :style="getBGStyle2()+'margin-top: 15pt; width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>INFO</b></span>
					
			        </div>
					<table border="0" width="100%" style="font-size: 9pt; margin-bottom: 6pt;">
						<!--
					<tr>
						<td colspan="1" class="bold">Target: {{targetType.name}}</td>
						
						<td colspan="1" v-if="targetType.budget">{{format(placement.target)}}</td>
						<td colspan="1" v-else>{{placement.target}}</td>
						<td class="right">
							<div style="display: block; width: 100%;">
							<GProgress v-if="targetType.spots && placement.target" :percentage="getPercent(markedSpots+inventoryPlacementResult.spotsPlacementTotal-inventoryPlacementResult.spotsPlacementTotalFree, {}, placement.targetTotal )"/>
							<GProgress v-else-if="targetType.budget && placement.target" :percentage="getPercent(inventoryPlacementResult.budgetPlacementTotal/(0.01*placement.payPercentage), {}, placement.budget )"/>
							<GProgress v-else-if="targetType.grp && placement.target" :percentage="getPercent(markedGRP +grpPlacementTotal, {}, placement.targetTotal )"/>
							</div>
						</td>
					</tr>
					<tr>
						<td colspan="1"></td>
					    <td colspan="1" class="bold" style="text-align: right;">Paid</td>
						<td colspan="1" class="bold" style="text-align: right;">Free</td>
					</tr>
					<tr>
						<td colspan="1" class="bold">Spots</td>
						<td colspan="1" style="text-align: right;">{{inventoryPlacementResult.spotsPlacementTotal-inventoryPlacementResult.spotsPlacementTotalFree}}</td>
						<td colspan="1" style="text-align: right;">{{inventoryPlacementResult.spotsPlacementTotalFree}}</td>
					</tr>
					<tr>
						<td colspan="1" class="bold">Budget</td>
						<td colspan="1" style="text-align: right;">{{format(inventoryPlacementResult.budgetPlacementTotal)}}</td>
						<td colspan="1" style="text-align: right;">{{format(inventoryPlacementResult.mediaBudgetPlacementTotal-inventoryPlacementResult.budgetPlacementTotal)}}</td>
					</tr>
					<tr>
						<td colspan="1" class="bold">Ratecard</td>
						<td colspan="1" style="text-align: right;">{{format(inventoryPlacementResult.mediaBudgetPlacementTotal)}}</td>
					</tr>
					<tr v-if="placement.targetgroupId && grpPlacementTotal">
						<td colspan="1" class="bold">GRP</td>
						<td colspan="1" style="text-align: right;">{{format(inventoryPlacementResult.budgetPlacementTotal)}}</td>
						<td colspan="1" style="text-align: right;">{{format(inventoryPlacementResult.mediaBudgetPlacementTotal-inventoryPlacementResult.budgetPlacementTotal)}}</td>
					</tr>
					<tr>
						<td colspan="1">Free ratio (target/act)</td>
						<td colspan="1" style="text-align: right;">{{placementInfo.incentiveDiscount}}%</td>
						<td v-if="inventoryPlacementResult.budgetPlacementTotal" colspan="1" style="text-align: right;">{{ Math.round(100*(inventoryPlacementResult.mediaBudgetPlacementTotal-inventoryPlacementResult.budgetPlacementTotal)/(inventoryPlacementResult.budgetPlacementTotal))}}%</td>
					</tr>
					-->
					<tr>
						<td colspan="1" >Outside current grid</td>
						<td colspan="2" style="text-align: left;">{{ Math.max(0,inventoryPlacementResult.spotsPlacementOutside)}}</td>
					</tr>
					<tr>
						<td colspan="1" >First booking</td>
						<td colspan="2" style="text-align: left;">{{ printDate(inventoryPlacementResult.bookingsFrom)}}</td>
					</tr>
					<tr>
						<td colspan="1" >Last booking</td>
						<td colspan="2" style="text-align: left;">{{ printDate(inventoryPlacementResult.bookingsTo)}}</td>
					</tr>
					</table>	
			       
					<button class="button myButtonSmall" :active="graphToShow=='weekPaid'" @click="graphToShow='weekPaid'; startChart()">Week</button>
					<button class="button myButtonSmall" :active="graphToShow=='hour'" @click="graphToShow='hour'; startChart()">Hour by valuea</button>
					<button class="button myButtonSmall" :active="graphToShow=='hourSpots'" @click="graphToShow='hourSpots'; startChart()">Hour by spots</button>		
					<div :style="getAppStyle()+'width: 100%; font-size: 12px; height: calc(20vH) !important; overflow-y: hidden;'">
						 	<div :style="getAppStyle()+'width: 100%;  font-size: 12px;'">
						 	
						 	        <div style="color: #000;" id='aChart' />
							       
							 </div>
					</div>
					
					<!-- ACTIONS -->    
					<div style='margin-top: 10pt;' class="BMSection" :style="getBGStyle2()+'width: 100%; !important'">
			              <span class='BMSectionDetail dontWrap90 smallText'><b>ACTIONS</b></span>
			        </div>
					<div >
			        	<button :disabled="!markedSpots" @click='bookMarked($event)' :style="getAppStyle()" class='button myButton'>Book {{markedSpots}} Spots</button>
			        	<button :disabled="!markedSpots" @click='cancelMarked()'     :style="getAppStyle()" class='button myButton'>Cancel {{markedSpots}} Breaks</button>
						
			        </div>
			    </div> <!-- CONTROLS -->
    		</div>
    		</div>
    
    		<!-- MAIN -->
            <div class='transition' v-if="inventoryPlacementResult.days" style='float: left; width: 57%; height: calc(100vH - 120px); overflow-y: auto; position: relative;'> 
				
				<div v-for="(result,ri) in inventoryPlacementResult.gridPeriodResult" :key="'r'+ri" style='width: 100%; float: left; position: relative;' >
					   <!-- LEFT TABLE-->
					   <div class='BMHeadActions' :style="'background-color: '+getBG()+' !important; height: 21pt; margin-top: 0;'">
						   <img @click="$refs.editor.open('Media', result.channel.id);" 
						        :style="'background-color: '+getBGColor('#fff','#444') +';float:left; height: 100%;'" :src="getImageData(result.channel.logo)"/>
				           <div class="channelTitle" >{{result.channel.name}} - <span class="placementName" @click="$refs.editor.open('Placement', placementInfo.placement.id);">{{placementInfo.placement.name}}</span> {{duration}}" 
						   <span style="float: right; width: 35%;">	
							<vue-slider style='z-index: 0;' :min="0" :max="lastDay-14" v-model="firstDay"></vue-slider>
							</span>
						   </div>
					   </div>
			           <div style='float: top; width: 100%; position: relative;'>
				           <div style='float: left; display: flex;'>
					            <div>
								    <div :style="'background-color: '+getBG()+';float: top left; overflow-y: hidden; cursor: pointer;'" class='BMHeadActions22'
								         @click="markAll(result)" title='mark *ALL* visible breaks'>
								        <div class="BMday transition" style="width: 40pt !important;">
									        <div class='BMheader BMheadLine' :style="getStyle2('channel')">
									       
										    </div>
										</div>
										<div class="BMday transition" style="width: 40pt !important;">
									        <div class='BMheader BMheadLine' :style="getStyle2('break')">
									         
										    </div>
										</div>
								   </div>
								   <div style='float: top left;'>
		   						   		<div v-for="(grid,gi) in filterGrid(result.grids)" :key="'g'+gi">
											
		   							        <div style='float: top left; overflow-y: hidden;' class="BMday transition " >
		   								        <div class='BMheader BMtime' :style="getStyleFor('channel', result.channel, grid)" @click="markAllGrid(grid)">
		   									    	{{printTime2(grid.time)}}
		   									    </div>
		   									     
		   									</div>
		   									<div style='display: table-cell;'>
			   									<div class='BMheader' :style="getStyleFor('full', result.channel, grid)">
			   										<div style='display: block;' class="day transition" >
			   											<div class="BMday transition">
			   										        <div class=" BMheader BMprogram" title='mark all visible breaks for this grid' 
			   										             @click="markAllGrid(grid)" :style="getStyleFor('break', result.channel)">
			   											    	{{grid.gridName}}
			   											    </div>
															
														    <div style='display: table-cell;'>
			   											        <div class='BMheaderInv BMinfo'  :style="getStyleFor('break')+';color: transparent;width: 100%;'"></div>
	
				   												<div class='BMheader BMX' :style="getStyleFor('break', result.channel, grid)">
				   													<div style='display: block;' class="BMday transition" 
																	     v-for="(slot,si) in filterSlot( grid.slots)" :key="'s'+si">
				   														<div class="BMday transition" :style="'display: block; '+getStyleFor('break')" >
																		    <div title='mark all visible Breaks'>
				   															    <div class='BMbreak BMLabel BMheader' @click="markAllSlot(slot)" :style="getStyleFor('break')">
				   															       {{ slot.label }}
				   															    </div>	
				   														    </div>
				   														    
				   														</div>    
				   													</div>
			   										
			   												</div>
			   											</div>
													</div>
			   										    
			   									</div>
			   							
			   								</div>
		   								</div>
		   							        
		   							 </div>
		   						 </div>	  
								   
							   </div>  
						   </div>
			        	</div>
						<!-- LEFT TABLE-->
						
						<!-- DAILY GRID -->
						
					       <div  :style='getStyleForDayTable()'>
					            <div :style="'display: block; background-color: '+getBGColor('#f8f8f8','#080808')+' !important;'">
									<div  :style="'background-color: '+getBG()+';display: block'" class="BMHeadActions22">
									    <div >
					
										    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'d_'+idx">
											    <div  class='BMheaderDay BMheadLine' :style="'font-weight: normal;'+getStyleFor('day')" @click="markAllDay(day, null, result)">
											    <b>{{day.weekday}} {{ day.name}}</b>
											    </div>	
										    </div>
									   </div>
									   <div  >
									   		
			   							    <div class="BMday transition" v-for="(day,idx) in filterDays(result.days)" :key="'i_'+idx" >
			   								    <div class='BMheaderDay BMbreak BMCounter' title='mark all visible Breaks on this day' @click="markAllDay(day, null, result)" :style="getStyleFor('day')">
			   								   {{ day.spotCount.spots}} <!-- SPOT ON DAY-->
			   								    </div>	
			   							    </div>
			   						   </div>
								  </div>
								
								   <div style='display: block; float: top left;  overflow-y: hidden;'>
			   					   		<div v-for="(grid,gi) in filterGrid(result.grids)" :key="'gx'+gi">
											
											<div style='float: top left; overflow-y: hidden;'>
																			   		
				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
				   								    <div class='BMheaderDay BMbreak BMprogramDate' title='mark all visible Breaks on this day' @click="markAllDay(day,filterSlot(grid.slots))" :style="getStyleFor('day')">
				   								    <b>{{day.weekday}} {{ day.name}}</b>
				   								    </div>	
				   							    </div>
				   						   </div>
										   
										   <div style='float: top left; overflow-y: hidden;'>																	   		
   				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
   				   								    <div class='BMheaderDay BMbreak BMCounter' title='mark all visible Breaks on this day' @click="markAllDay(day,filterSlot(grid.slots))" :style="getStyleFor('day')">
   				   								    <template v-if="grid.spotsOnDay[day.no]">{{ grid.spotsOnDay[day.no].spots}}</template> <!-- SPOT In Grid-->
													<template v-else>-</template> <!-- SPOT In Grid-->
   				   								    </div>	
   				   							    </div>
   				   						   </div>
										   
										   <div  v-for="(slot,si) in filterSlot(grid.slots)" :key="'sx'+si"  style='float: top left; overflow-y: hidden;'>																	   		
  				   							    <div class="BMday transition" v-for="(day,idx) in filterDays(inventoryPlacementResult.days)" :key="'i_'+idx" >
													<div v-if="bookingMode">
														<div v-if="filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))">
	  				   								        <div v-for="(daySlot,dsi) in [filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))]" :key="'ds'+dsi" 
																   class='BMheaderDay BMadbreak' 
																   @click='mark(day, daySlot)'  
																   @drop="onDrop($event, daySlot, day)"
														           @dragover.prevent
		  														   @dragenter.prevent
																   @contextmenu.stop.prevent="nodeRClicked($event, result.channel, day, daySlot)"
																   :style="getStyleFor('day')">
																<div class='todrag' draggable @dragstart="startDrag($event, daySlot, day)" :style="getAppStyle()" >
																<BookingGridBreak :exclusion="daySlot.exclusion" :forDuration=duration :clashCode="daySlot.clashCode" :options=options :daySlot="daySlot"/>
																															
																 	<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" :fill="markedColor( day, daySlot)" class="bi bi-check" viewBox="0 0 11 11">
																	  <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"/>
																	</svg>
																</div>
															</div>
															
														 </div>
														 <div v-else class='BMheaderDay notAvail' 
														      title='mark all visible Breaks on this day' @click='markAllDate(channel, day)' 
															  :style="getStyleFor('day')+'; background-color: '+(options.unavailColor||'#eee')+';'">
	  				   								  		
	  				   								    </div>	
													</div>	
													<div v-else >
														<div v-if="filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))">
	  				   								        <div v-for="(daySlot,dsi) in [filterDaySlot(slot.slots.find(p=>p.dayNo==day.no))]" :key="'ds'+dsi" 
																   class='BMheaderDayAV' 
																   @contextmenu.stop.prevent="nodeRClicked($event, result.channel, day, daySlot)"
																   :style="getAppStyle()+getStyleFor('day')">
																
																<BookingGridBreakAV :forDuration="duration" :daySlot="daySlot" :display="avMode"/>
											
															</div>
															
														 </div>
														 <div v-else class='BMheaderDay notAvail' 
														      title='mark all visible Breaks on this day' @click='markAllDate(channel, day)' 
															  :style="getStyleFor('day')+'; background-color: '+(user.bookSettings.unavailColor||'#eee')+';'">
	  				   								  		
	  				   								    </div>	
													</div>		
  				   							    </div>
  				   						   </div>
										 
										</div>
									</div>
							</div>
						
						<!-- DAILY GRID -->
					</div>
				</div>
			</div> <!-- mainContent -->
			
	      </slot>
	    </div> <!-- modal-body -->
	  </div> <!-- modal-container -->  	  
	  
	 </div> <!-- modal-wrapper -->  
   
    <ContextMenu ref="menu">
      <template v-if="contextData"  slot-scope="{ contextData }">
		
		<ContextMenuItem v-if="bookingMode && markOnlyAvailable"  @clicked="markOnlyAvailable = false; $refs.menu.close();">
          Mark all breaks (for cancellation)
        </ContextMenuItem>
		<ContextMenuItem v-else-if="bookingMode" @clicked="markOnlyAvailable = true; $refs.menu.close();">
          Mark only available breaks (for booking)
        </ContextMenuItem>
		
		<ContextMenuItem v-if="!bookingMode" @clicked="bookingMode=true; $refs.menu.close();">
		  Booking mode
		</ContextMenuItem>
		<ContextMenuSep />
		<ContextMenuItem v-if="avMode!='free'" @clicked="bookingMode=false; avMode='free'; $refs.menu.close();">
		  Show availability (free)
		</ContextMenuItem>	
		<ContextMenuItem v-if="avMode!='price'" @clicked="bookingMode=false; avMode='price'; $refs.menu.close();">
		  Show availability (price)
		</ContextMenuItem>	
																	
		<ContextMenuSep v-if="contextData.channel"/>
        <ContextMenuItem v-if="contextData.key" @clicked="openBreak($event, contextData.key, contextData.slot)">
          Open Break {{contextData.day.name}} / {{contextData.slot.code}}
        </ContextMenuItem>
        <ContextMenuItem v-if="contextData.day" @clicked="openDetailPlan( contextData.channel, contextData.day.date)">
          Open RunDown Screen {{contextData.day.name}}
        </ContextMenuItem>
       
       </template>
    </ContextMenu>
    <BreakView ref='breakView' :reference=selectedRef :time="new Date().getTime()" 
        :options=options
        @saveUser=saveUser
        @deleteSpot="deleteSpot"
        @setTypeForSpots="setTypeForSpots"
        @close="closeBreakView()">
    </BreakView>
	<GFWEOpenEditor ref='editor' @update='doReload'></GFWEOpenEditor>

   
 </div>
</template>

<script>
import GFWTreeView from '@/components/GFWTreeView';
import InputWeekdays from '@/components/inputElements/InputWeekdays';
import InputDatePick from '@/components/inputElements/InputDatePick';
import InputTime from '@/components/inputElements/InputTime2';
import GProgress from '@/components/misc/GProgress';
import BreakView from '@/components/BreakViewNew';
import BookingGridBreak from '@/components/booking/BookingGridBreakNew';
import BookingGridBreakAV from '@/components/booking/BookingGridBreakAvail';
import GSelect from '@/components/misc/GSelect';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import ContextMenuSep from '@/components/ContextMenuSep';
import {HTTP, fwAPI, invAPI, bngAPI, setReload, formatNumber} from '@/variables.js';
import { getAppStyle, setDarkMode, initAppMode, getBGStyle2, getBG, getBGColor, isDarkMode } from '@/AppStyle.js';
import {fmtTimePart, printTimeHHMM, printTime2 } from '@/basicTimeFN.js';
import { formatPercent, format } from '@/Numbers.js';
import { getDataByName}  from '@/utils.js';
import { getRawKey, getRawKeyById } from '@/bookMan.js';
import { setGoBack } from '@/breadCrumb.js';
import { openView } from '@/utils.js';
import ApexCharts from 'apexcharts'
var momentTZ = require('moment-timezone');
var numeral = require('numeral');
var timers = [];
export default {
  name: 'GFW BookingGrid',
  components : {
    ContextMenu, ContextMenuItem, ContextMenuSep, BreakView, GSelect
	, BookingGridBreak, BookingGridBreakAV
  },
  props: {
  	inventoryPlacementResult: Object, 
  	targetType: Object,
    options: Object,
    targetGroup: Object,
    markBreaks: Array,
    spotsDay: Array,
    spotsGrid: Array,
    spotsUnplacedBreak: Array,
    spotsBreak: Array,
    consumedGrid: Array,
    consumedBreak: Array,
    spotsBreakTotal: Array,
    invalidSpots: Array,
	duration: Number,
    user: Object,
    invalidSpotsChanged: {type: Number, defaultValue: 0},
    allBookingStats: Array,
    placementInfo: Object,
    fromDate: String,
    untilDate: String,
    inputWeekdays: {type: Number, defaultValue: 127},
    inputBreakTypeIds: Array,
    time: Number
  },
  data () {
    return {
      placement: {},
      placementFrom: null,
      dayHasBreak: null,
      placementTo: null,
      openThisModule: String,
      inventory: {},
      gridData: [],
      mediaSet: {},
      channels: {},
      channelDiffs: [],
      timeSlots: {},
	  graphToShow: 'weekPaid',
	  markOnlyAvailable: true,
	  bookingMode: true,
	  avMode: "xxx",
      
      durationData: [],
      breakTypeData: [],
      plcmBreakTypes: [],
      
      showEditor: false,
      entityMap: {},
      spotListTime: 0,
      mapping: [],
      metadata: {},
      dataId: 0,
	  firstDay: 0,
	  lastDay: 0,
	  chart: null,
	  chartOptions: null,
      //
      allMedia: [],
      selectedMedia: [],
      allTimeslots: [],
      selectedTimeslots: [],
      allPG: [],
      selectedPG: [],
      allGrids: [],
      selectedGrids: [],
      allCats: [],
      selectedCats: [],
      //
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      widths: [],
      weekdayLabel: [],
      weekdays: [],
      weekdayBITS: [],
      marked: [],
      dates: [],
      isoDates: [],
      fontSize: {},
      limitDays: 60,
      spotsPlacement: [],
      
      spots: 0,
      markedSpots: 0,
      markedGRP: 0,
      shown: false,
      showBreakView: false,
      
      selectedRef: "",
      selectedGrid: {},
      loader: {},
      format,getBG,
      getAppStyle,
      getBGStyle2,
	  getBGColor,
      loadingActive: false
    }
  },
  beforeUpdate: function () {
  	this.getOpenThisModule();
  },

  methods: {
    getOpenThisModule() 
    {
    	this.openThisModule = this.$route.query.toOpen;
    	return this.openThisModule;
    },
	printDate( date)
	{
		let fmt = sessionStorage.dateFormat;
	    let time = date;
        try
        {
            time = momentTZ(new Date(date)).tz(sessionStorage.timezone).format(fmt);
        }
		catch (e)
		{
			//
		}
		return time;
	},
	getImageData(val) { return val?'data:image/png;base64,' + val: 'data:image/png'; },
	filterDays( days )
	{
		if ( days && days.length)
		{
			if ( this.firstDay  )
			{
				try {
					return days.filter( p => p && p.no >= parseInt(this.firstDay) && (this.inputWeekdays & p.weekdayBit));
				} catch ( e )
				{
					return days.filter( p => p && (this.inputWeekdays & p.weekdayBit));
				}
			}
		}
		return days.filter( p => p && (this.inputWeekdays & p.weekdayBit));
		
	},
	available( slot)
	{
		if ( slot.exclusion )
		{
			return false;
		}
		if ( slot.clashCode)
		{
			return false;
		}
		if ( slot.clashCode )
		{
			return false;
		}
		if ( this.duration > slot.available )
		{
			return false;
		}
		return true;
	},	
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 18%; height: calc(100vH - 234px); padding: 1ex;"
    	}
    	return "float: left; width: 18%; height: calc(100vH - 200px); padding: 1ex;"
    },
	markAllSlot( slot)
	{
		for ( let dsi in slot.slots)
		{
			let daySlot = slot.slots[dsi];
			let day = this.inventoryPlacementResult.days.find( d=>d.no==daySlot.dayNo);
			this.mark( day, daySlot)
		}
	},
	markAllDay( day, gridSlots, result)
	{
		if ( gridSlots )
		{
			for ( let si in gridSlots)
			{
				let slot = gridSlots[si];
				let relevantSlots = slot.slots.filter(p=>p.dayNo == day.no);
				for ( let dsi in relevantSlots )
				{
					let daySlot = relevantSlots[dsi];
					this.mark( day, daySlot)
				}
			}
		}
		else if ( result )
		{
			let allGrid = this.filterGrid( result.grids);
			for ( let gsi in allGrid)
			{
				let grid = allGrid[gsi];
				this.markAllDay( day, this.filterSlot(grid.slots));
			}
		}
			
		
	},
	markAll( result)
	{
        if ( result )
		{
			let allGrid = this.filterGrid( result.grids);
			for ( let gsi in allGrid)
			{
				let grid = allGrid[gsi];
				for ( let si in grid.slots)
				{
					let slot = grid.slots[si];
					this.markAllSlot( slot)
				}
			}
		}
			
		
	},
	markAllGrid( grid)
	{
		for ( let gsi in grid.slots)
		{
			let slot = grid.slots[gsi];
			this.markAllSlot( slot)
		}
	},
    limit(dates)
     {
     	let maxLen = this.limitDays;
        if ( dates.length > maxLen )
        {
        	return dates.slice().splice( 0, maxLen );
        }
        return dates;
     },
     getPercent( spots, targetType, target)
    {
    	return target==0? 100: Math.round(10000*spots/target)/100;
    }, 
    getMainContentStyle()  { return "height: "+ this.mainHeight+"% !important;"; },
    fmt( val)
    {
       return formatNumber( val );
    },
    openDetailPlan(station, isoDay)
    {
    	setGoBack( this.$router.currentRoute.path, this.$router.currentRoute.name);
    	openView( this.$router, this.$toast, 'detailPlanning?stationId='+station.id+'&day='+ isoDay);
    },
    adSpace(iGrid)
    {
    	
    	if ( iGrid.replacements )
    	{
    		let r = iGrid.replacements.find( p=>p.date !== null);
    		if ( r)
    		{
    			//alert( JSON.stringify( iGrid.adspace))
    			return iGrid.adspace.concat( iGrid.adspace);
    		}
    	}
    	return iGrid.adspace;
    },
    saveUser( user)
    {
    	this.$emit("saveUser", user);
    },
    deleteSpot( toDelete)
    {
		//alert( JSON.stringify( toDelete))
    	this.$emit("deleteSpot", toDelete);
    },
    setTypeForSpots( mySpots, type)
    {
    	this.$emit("setTypeForSpots", mySpots, type);
    },
    closeDetails() {
       this.treeHeight=90.0;
       this.placements = [];
       this.gridValid = [];
       this.inventory={};
       this.placement={};
       this.selectedGrid={}
       this.showPlacementGrid = false;
       this.showPlacementSpots = false;
    },
    
    getWidth() 
    {
    	let max = window.innerWidth;
    	
    	let avail = (max * 0.55) - 120;

    	return avail;
    },
    getHeight() 
    {
    	let max = window.innerHeight;
    	
    	let avail = (max)-120;

    	return avail;
    },
    startDrag (evt, source, day) {
		
        evt.dataTransfer.dropEffect = 'move'
        evt.dataTransfer.effectAllowed = 'move';
		evt.dataTransfer.setData('fromBreak', getRawKeyById( source.breakGridId, source.breakId, day.date))
		//alert( JSON.stringify( evt.dataTransfer.getData('fromBreak')))
	},
	onDrop (evt, target, day) {
		//alert( "-->" +target.breakGridId + "/" + day)
		const source = evt.dataTransfer.getData('fromBreak')
		if ( source )
		{
			var fromTo = new Array();
			fromTo.push( source);
			fromTo.push( getRawKeyById( target.breakGridId, target.breakId, day.date));
			//alert( JSON.stringify( fromTo))
			this.$emit("bookMove", fromTo )
		}
		const spots = evt.dataTransfer.getData('spots')
		if ( spots )
		{
			const setToStatusId = evt.dataTransfer.getData('setToStatusId');
			fromTo = { spots: JSON.parse(spots), setToStatusId: setToStatusId?setToStatusId:0, line: target };
			this.$emit("moveSingleSpot", fromTo )
		}
		
	},
    nodeRClicked(event, channel, day, daySlot)
    {
		let key = getRawKeyById( daySlot.breakGridId, daySlot.breakId, day.date);
		this.$refs.breakView.close();
        this.$refs.menu.open(event, { key: key, channel: channel, day: day, slot: daySlot} )
    },
	mouseRClicked(event)
    {
        event.preventDefault();
        this.$refs.menu.open(event, { } )
    },
    openBreak( event, ref, slot ) {
    	this.selectedRef = ref;
    	this.$refs.menu.close();
    	this.$refs.breakView.open(ref, slot ,this.duration);
    },
    closeBreakView() {
    	this.$refs.breakView.close();
    },
    getMainStyle()
    {
    	return "padding: 10pt 10pt 10pt 10pt; float: left; width: 75%; height: "+this.getHeight()+"px; overflow-y: scroll;";
    },
    filter( breakTypes )
    {
    	var copy = new Array;
    	
    	for ( var bi in breakTypes )
    	{
    		let b = breakTypes[bi];
    		if ( this.breakTypeVisible( b.id))
    		{
    			copy.push(b);
    		}
    	}
    	return copy;
    }, 
    breakTypeVisible( typeId )
    {
    	return this.inputBreakTypeIds.includes(typeId);
    },
    selectedSpots(unselect)
    {
    	var spots = new Array()
    	for ( var x in this.marked )
    	{
    		if ( this.marked[x] )
    		{
    		    if ( unselect )
    		    {
    		    	this.marked[x] = false;
    		    }
    			spots.push( x );
    		}
    	}
    	return spots;
    },
    bookMarked()
    {
        var spots = this.selectedSpots(false);
    	var bookReq = {placement: this.placementInfo.placement, lines: spots};
    	//alert( JSON.stringify(bookReq));
    	this.$emit("bookMarked", bookReq )
    },
    cancelMarked()
    {
        var spots = this.selectedSpots(true);
    	this.$emit("cancelMarked", spots )
    },
    getKey(channel, grid, adspace, breaktype, day)
    {
		if ( adspace && adspace.id )
		{
			return getRawKeyById( adspace.id, this.isoDates[ day]);
		}
		if ( adspace && adspace.no )
		{
    		return getRawKey( channel, grid.detail.id, adspace.no, breaktype.id, this.isoDates[ day]);
		}
		return "-";
    },

    getDayDiff( d, start)
    {
    	let oneDay = 1000 * 60 * 60 * 24;
    	let day = Math.floor(d-start / oneDay);
    	return day;
    },
	
    mark( day, daySlot)
    {
		if ( this.markOnlyAvailable && !this.available( daySlot))
		{
			return;
		}
		if (!this.filterDaySlot( daySlot))
		{
			return;
		}
    	let idx = getRawKeyById( daySlot.breakGridId, daySlot.breakId, day.date);
    	//alert( idx)
    	if ( this.marked[ idx] )
    	{
    		this.marked[ idx] = false;
    		this.markedSpots--;
    	}
    	else
    	{
    		this.marked[ idx] = true;
    		//this.$set( this.marked, idx, true);
    		this.markedSpots++;
  
    	}
    	
    	//this.$forceUpdate();
    },
    getAdBreakClass( channel, grid, adspace, breaktype, day)
    {
    	let idx = this.getKey(channel, grid, adspace, breaktype, day);
    	//console.log("CHECK>> "+ idx + " " +this.marked[ idx]);
    	if ( this.marked[ idx] )
    	{
    	    
    		return "BMadbreakMarked"
    	}
    	return "BMadbreak";
    },
    isMarked( day, daySlot)
    {
    	let idx = getRawKeyById( daySlot.breakGridId, daySlot.breakId, day.date);
    	//console.log("CHECK>> "+ idx + " " +this.marked[ idx]);
    	return this.marked[ idx];
    },
    markedColor( day, daySlot)
    {
    	if ( this.isMarked(day, daySlot))
    	{
    		return isDarkMode() ? "yellow": "green";
    	}
    	return "transparent";
    },
    getStyleForDayTable()
    {
    	return "float: top left; display: flex; width: "+this.getWidth()+"px !important; position: relative; overflow-x: clip;"
    },
	getStyleFor(name, channel, grid, slot)
    {
        var w = 50;
        var h = 1;
        
		if ( channel && grid && grid.slots && name != 'day')
		{
		    h = this.filterSlot(grid.slots).length;   
			if ( name != 'full' && name != 'break' )
        	{ 
        		h += 2;
        	} 		
		}
		else if ( channel && (grid) )
        {
            h = 2*this.filterSlot(grid.slots).length;
        	
        }
		if ( name === 'program' )
        {
        	w=50;
    	}
		if ( name === 'channel' )
        {
        	w=40;
    	}
		if ( name === 'break' )
	    {
	    	w=50;
		}
		if ( name === 'day' )
	    {
	    	w=40;
		}
    	if ( name == 'full' )
    	{
    		return "height: "+(14*(h))+"pt; width: 100% !important;";
    	}
    	return "position: relative; height: "+(14*h)+"pt; width: "+w+"pt !important; max-width: "+w+"pt !important; min-width: "+w+"pt !important;";
    },
    getStyle2(name, channel, slot, grid)
    {
        var w = 30;
        var h = 1;
     
        if ( name === 'channel' )
        {
        	w=40;
    	}
    	if ( name === 'break' )
        {
        	w=50;
    	}
    	if ( name == 'full' )
    	{
    		return "height: "+(28*h)+"pt; width: 100% !important";
    	}
    	return "height: "+(28*h)+"pt; width: "+w+"pt !important; valign: bottom !important";
    },

    dayStyle() {
    	return "float: left; width: 4%; ";
    },
    
    closeEditor()
    {
         this.showEditor = false;
    },
    
	weekdaySet(weekdays, date)
	{
		let wd = this.weekdays[date];
    	return ( weekdays & wd )
	},
    setPlacement(info) { 
       let that = this;
	   that.placement = info.placement;
       //this.startLoader();
       //console.log("CHECKPT setPlacement()");
       if ( info.placement.id != that.placement.id )
       {
       		this.clearFilter();
       }
       
       let flt = { media: this.selectedMedia, grids: this.selectedGrids, pg: this.selectedPG, timeslots: this.selectedTimeslots, categs: this.selectedCats };
      
       that.placementFrom = new Date( this.placement.fromDate);
       that.placementTo = new Date( this.placement.untilDate);
       that.inventory = info.inventory;
       that.plcmBreakTypes = new Array();
       that.breakTypeData = info.allBreakTypes;
       for ( var bt in that.breakTypeData )
       {
          let breakType = that.breakTypeData[bt];
          //alert(bt +"-->"+ JSON.stringify(breakType));
          if ( !that.placement.breakTypes || that.placement.breakTypes.length==0 || that.placement.breakTypes.includes( breakType.id) )
          {
          	that.plcmBreakTypes.push( breakType);
          }
       }
       //console.log("CHECKPT 4");
       
	   that.breakTypeData = that.plcmBreakTypes.slice();
	   
	   //console.log(JSON.stringify(that.breakTypeData));
	   //that.getAdBreaks(that.placement);      
	   //this.stopLoader(); 
    },
	startChart()
		{
			let data = this.placementInfo.weekLimits;
			let that = this;
			let labels = null;
			let series = null;
			if ( this.graphToShow == 'week')
			{
				labels = data.limits.map(p=>'W'+p.week);
				let total = (100+that.placementInfo.incentiveDiscount)*.01;
				
				series = [
							{"name":"Target paid","data": data.limits.map(p=>p.budget/1000)},
					        //{"name":"Booked paid","data": data.limits.map(p=>p.actualBudgetPaid/1000)}]
						    {"name":"Target total","data": data.limits.map(p=>p.budget*total/1000)},
					        //{"name":"Booked total","data": data.limits.map(p=>p.actualBudget/1000)}
					     ];
			}
			else if ( this.graphToShow == 'weekPaid')
			{
				labels = data.limits.map(p=>'W'+p.week);
				let total = (100+that.placementInfo.incentiveDiscount)*.01;
				
				series = [
							{"name":"Booked paid",
							  "data": data.limits.map(p=>{ return { x: "Booked paid", y: p.actualBudgetPaid/1000, goals:[ { name: "exp", value: p.budget/1000, strokeColor: '#775DD0'}]}})
							},
							{"name":"Booked total",
							  "data": data.limits.map(p=>{ return { x: "Booked paid", y: p.actualBudget/1000, goals:[ { name: "exp", value: p.budget*total/1000, strokeColor: '#775DD0'}]}})
							}]					  
							;
			}
			else if ( this.graphToShow == 'weekValue')
			{
				labels = data.limits.map(p=>'W'+p.week);
				let total = (100+that.placementInfo.incentiveDiscount)*.01;
				
				series = [
								//{"name":"Target paid","data": data.limits.map(p=>p.budget/1000)},
						        //{"name":"Booked paid","data": data.limits.map(p=>p.actualBudgetPaid/1000)},
							    {"name":"Target total","data": data.limits.map(p=>p.budget*total/1000)},
						        {"name":"Booked total","data": data.limits.map(p=>p.actualBudget/1000)}];
			}
			else if ( this.graphToShow == 'hour')
			{
				
				labels = this.inventoryPlacementResult.hourUsages.map(p=> p.hour);
				series = [
				    {"name":"Budget","data": this.inventoryPlacementResult.hourUsages.map(p=>p.budget/1000)},
					{"name":"Value","data": this.inventoryPlacementResult.hourUsages.map(p=>p.mediaValue/1000)}]
			        
			}
			else if ( this.graphToShow == 'hourSpots')
			{
				
				labels = this.inventoryPlacementResult.hourUsages.map(p=> p.hour);
				series = [
				    {"name":"Paid spots","data": this.inventoryPlacementResult.hourUsages.map(p=>p.spotsPaid)},
					{"name":"All Spots","data": this.inventoryPlacementResult.hourUsages.map(p=>p.spots)},
			        ];
			}
		
		//alert( this.graphToShow + JSON.stringify(series))
		 
		let colors = [ '#67b73a', '#67eeff', '#21f396', '#2196f3' ];
		let col = sessionStorage.fgColor;
		that.$forceUpdate();         	
		         	
		
		that.chartOptions = {
			  chart: { 
			    type: 'bar',
			    height: 160,
				width: "90%",
			    toolbar: {
		         show: false
			    },
			    animations: {
			        enabled: false
			    },
			    events: {
			    	 
			    }						
			  },

			  title: {
				//  text: "Sales requests in TUSD"
			  },
		  	  dataLabels: {
			  enabled: false,
			    style: {
			      colors: [col]
			    }
			
			  },
			  legend: {  labels: { colors: [col] }},
			  series: series,
			  markers: {
				  size: [4],
				  onClick: function(e) { alert(e)}
				},
			  stroke: {
				  width: 2
				},
			  xaxis: { 
				  categories: labels, 
				  labels: { style: {colors: [col,col,col,col,col,col,col,col,col]}}
			  },
			  tooltip: { custom: null, },
			  yaxis: {
				forceNiceScale: true,
		        decimalsInFloat: 1,
		        labels: { style: {colors: [col]}}
			  },
			  colors: colors
			}
		   that.distroyChart();
		   //alert(document.querySelector("#aChart"))
     	   that.chart = new ApexCharts(document.querySelector("#aChart"), that.chartOptions);
	
	       that.chart.render();
		   //alert(22)
	       that.$forceUpdate();

		},
		distroyChart()
			 	    {
			 	    	if ( this.chart)
			 			{
			 	    		try
			 	    		{
			 					this.chart.destroy();
			 					this.chart = null;
			 				}
			 	    		catch(e)
			 	    		{
			 	    		//
			 	    		}
			 			}
			 	    },
    printTimeslot( elem)
    {
    	return printTime2(elem.time) + " - " + printTime2(elem.time+elem.duration);
    },
    
	filterGrid( grids)
    {
		let tmp = [... grids];
		if ( this.selectedGrids && this.selectedGrids.length > 0 )
     	{
     		tmp =  tmp.filter(g => this.selectedGrids.includes(g.gridName) );
     	}
		if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
     	{
     		tmp =  tmp.filter(g => this.selectedTimeslots.includes( this.printTimeslot(g)) );
     	}
    	return tmp;
    },
	getPG( slot)
	{
		if ( slot.pricegroup < 10 )
		{
			return "P-0"+slot.pricegroup+" (" + slot.price+")";
		}
		return "P-"+slot.pricegroup+" (" + slot.price+")";
	},
	filterSlot( slots)
	    {
			
			try
			{
				
				if ( this.selectedPG && this.selectedPG.length > 0 )
		     	{
					let tmp = [];
					for ( let si in slots)
					{
		     			let newSlots = slots[si].slots.filter(g => this.selectedPG.includes( this.getPG(g) ));
						if ( newSlots && newSlots.length )
						{
							tmp.push( slots[si]);
						}
					}
					return tmp;
		     	}
				
		    	return slots;
			} catch (e)
			{
				return slots;
			}

	    },
	filterDaySlot( daySlot)
	{
		try
		{
			if ( this.selectedPG && this.selectedPG.length > 0 )
	     	{
	     		if ( this.selectedPG.includes( this.getPG( daySlot) ))
				{
					return daySlot;
				}
				return null;
	     	}
	    	return daySlot;
		} catch (e)
		{
			return daySlot;
		}
	},
    getGridData( tmp, keepFilters)
    {
        this.timeSlots = new Array();
        if (!keepFilters.includes("Grid"))
        {
        	this.allGrids = new Array();
        }
        if (!keepFilters.includes("PG"))
        {
        	this.allPG = new Array();
        }
        if (!keepFilters.includes("Timeslot"))
        {
        	this.allTimeslots = new Array();
        }
		for (let ri in tmp.gridPeriodResult)
		{
			let r = tmp.gridPeriodResult[ri];	
			if ( this.selectedGrids && this.selectedGrids.length > 0 )
	     	{
	     		r.grids =  r.grids.filter(g => this.selectedGrids.includes(g.gridName) );
	     		keepFilters.push('Grid');
	     	}
			if ( this.selectedTimeslots && this.selectedTimeslots.length > 0 )
	     	{
	     		r.grids =  r.grids.filter(g => this.selectedTimeslots.includes( this.printTimeslot(g)) );
	     		keepFilters.push('Timeslot');
	     	}
			for (let gi in r.grids)
			{
				let grid = r.grids[ gi];
				if (!keepFilters.includes("Grid"))
	        	{
		            if ( !this.allGrids.includes( grid.gridName))
		            {
		            	this.allGrids.push( grid.gridName);
		            }
		        }
				if (!keepFilters.includes("Timeslot"))
	        	{
	        		let ts = this.printTimeslot( grid);
		            if ( !this.allTimeslots.includes(ts))
		            {
		            	this.allTimeslots.push(ts);
		            }
		        }
				for (let sli in grid.slots)
				{
					let slot = grid.slots[ sli];
					for (let ssli in slot.slots)
					{
						let dayslot = slot.slots[ ssli];
						if (!keepFilters.includes("PG"))
			        	{
			            	if ( !this.allPG.includes( this.getPG( dayslot)))
			            	{
			            		this.allPG.push( this.getPG( dayslot));
			            	}
			            }
					}
				}
			}
		}

        this.allPG.sort( (a, b) => a.localeCompare(b));
        
    },
    clearFilter() { 
       this.startLoader();
       this.selectedMedia = new Array();
       this.selectedGrids = new Array();
       this.selectedPG = new Array();
       this.selectedTimeslots = new Array();
       this.selectedCats = new Array();
       this.selectedSpotReasons = new Array(); 
    },

   printTime(time)
   {
       		return printTimeHHMM(time);
   },
   printTime2(time)
      {
        return printTime2(time);
   },
   startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
	stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	},
	
  },
  computed: {
  	spotsSelected: { 
  	    get() {
	        var spots = new Array()
	    	for ( var x in this.marked )
	    	{
	    		if ( this.marked[x] )
	    		{
	    			spots.push( x );
	    		}
	    	}
	    	return spots;
      }
  	}
  },
  watch: {
	
	inventoryPlacementResult: 	function() {
        if ( this.inventoryPlacementResult )
        {
			this.marked= [];
			this.markedSpots = 0;
			this.lastDay = 10;
			if ( this.inventoryPlacementResult && this.inventoryPlacementResult.days && this.inventoryPlacementResult.days.length)
			{
				//this.firstDay = this.inventoryPlacementResult.days[0].no;
				this.lastDay = this.inventoryPlacementResult.days[this.inventoryPlacementResult.days.length-1].no;
				this.getGridData( this.inventoryPlacementResult, []);
				this.startChart();
			}
			
    	}
    },
    placementInfo: function() {
        if ( this.placementInfo )
        {
    		this.setPlacement( this.placementInfo);
			this.startChart();
    	}
    },
    markBreaks: function()
    	{
    		this.marked = [];
    		this.markedSpots = 0;
    		//this.markedGRP = 0;
    		for ( let x in this.markBreaks)
    		{
    			this.$set( this.marked, this.markBreaks[x], true);
        		this.markedSpots++;
    		}
    	},
    
    user: function() {
    	if ( this.user.fontSize )
        {
        	this.fontSize = this.user.fontSize;
        }
    }
  },
  created() {
	if ( this.placementInfo )
    {
		this.setPlacement( this.placementInfo);
	}
	this.firstDay = 0;
	this.lastDay = 10;
	if ( this.inventoryPlacementResult && this.inventoryPlacementResult.days && this.inventoryPlacementResult.days.length)
	{
		this.firstDay = this.inventoryPlacementResult.days[0].no;
		this.lastDay = this.inventoryPlacementResult.days[this.inventoryPlacementResult.days.length-1].no;
		this.getGridData( this.inventoryPlacementResult, []);
	}
	this.$nextTick(function () {
	    try
	    {
			this.startChart();
			this.$refs.menu.open(event, { });
	    } catch(e) { /* */ }
	  	this.$refs.menu.close();
		});
  },
  updated() {
  //console.log("update...");
  this.$nextTick(function () {
    
    if (this.loadingActive)
    {
        //console.log("stop loader: " + timers.length)
		
	    timers.push( setTimeout( this.stopLoader, 100));
    }
  })
  }
}
</script>
<style scoped>
ul {
  list-style: square inside none;
}
.bold {
  font-weight: bold;
}
.xtransition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
 .appLeft {
	display:  inline-table; 
	text-align: left;
	width: 70%; 
}  
.xtransitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.BMday {
  display: table-cell;
  max-width: 50pt !important;
  overflow-x: hidden;
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
}
.BMheaderDay { 
  font-size: 7pt; 
  display: table-cell;
  text-align: center;
  font-weight: bold;
  vertical-align: middle;
  align-items: center;
  justify-content: center;  
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  cursor: pointer;
  overflow-x: hidden;

  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
 }
 .BMheaderDayAV { 
   margin: 0 !important;
   padding: 0 !important;
   font-size: 7pt; 
	 display: table-cell;
	 text-align: center;
	 font-weight: bold;
	 vertical-align: middle;
	 align-items: center;
	 justify-content: center;  
	 border-left: 1px solid #aaa; 
	 border-bottom: 1px solid #ccc; 
	 cursor: pointer;
	 overflow-x: hidden;
	
	 white-space: nowrap; /* Don't forget this one */
	 text-overflow: ellipsis; 
}
 .right
 {
	float: right !important;
	
	align-items: right !important;;
	justify-content: right !important;; 
	text-align: right !important;
 }
.BMSection {
    margin-top: 0pt;
	height:20pt; 
	width: 100%;
	border: 1pt ridge #ccc;
	vertical-align: middle; 
	text-align: center;
	display: inline-flex; 
	color: #666; 
	background-color: #eee; 
	font-weight: bold; 
	font-size: 10pt;
}
.BMSectionDetail {
    padding-top:  3pt; 
    padding-left: 20pt;
    text-align: center;
	width: 100%;
}
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.dontWrap90 {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 50%;
  display: flex;
}
.smallText {
  font-size: 9pt;
  height: 12pt;
}

.BMnoadbreak {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #bbb;
  background-color: #eee;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.channelTitle {
	height: 22px;
	display: block;
	padding-left: 5pt;
	padding-top: 4px;
	vertical-align: middle;
	align-items: center;
	justify-content: center;  
	overflow-x: hidden;
	overflow-y: hidden;
	font-size: 10pt;
	font-weight: bold;
}
.BMnoadbreakExcl {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #bbb;
  background-color: #fcc;
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  overflow-x: hidden;
  overflow-y: hidden;
  font-size: 7pt;
}
.BMadbreak1 {
	display: inline-block; 
	width: 66%;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
    vertical-align: top; 
    padding-top: 0pt;
   
    margin: 0pt;
    height: 100%;
    margin: 0pt;
}
.BMadbreak {
	
}
.BMadbreak2 {
	display: inline-block; 
	width: 28% !important; 
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
	height: 100%;
	margin: 0pt;
    padding: 0pt;
}
.BMadbreakMarked {
  border-left: 1px solid #aaa; 
  border-bottom: 1px solid #ccc; 
  color: #f0c;
  background-color: #bcc; 
  text-align: center;
  vertical-align: bottom;
  align-items: center;
  justify-content: center;  
  font-size: 7pt;
}
.myButton {
	//width: 18%;
    padding: 6px 8px;
    outline: none;
    border-radius: 3px;
    height: 24pt;
    font-size: 10pt;
    background-color: #eef;
    border: 1px outset #aaa;
    color: rgb(0, 0, 0);
    margin-top: 4pt;
    margin-right: 4pt;
    margin-bottom: 4pt;
}
.BMbreak:hover {
  cursor: pointer;
}
.BMadbreak:hover {
	color: #000 !important;
	background-color: #ccf !important; 
	border-left: 2px solid blue !important;
	border-right: 2px solid blue !important;
}
.BMadbreakMarked:hover {
  color: #000 !important;
  background-color: #ccf !important; 
}
.BMheader { 
  display: block;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: 8pt; 
  font-weight: bold;
  white-space: nowrap; /* Don't forget this one */ 
 }
.BMheaderInv {
	color: transparent;
}
.BMprogram {
	
	border-bottom: 1px solid grey; 
	//border: 1px solid #fcc; 
	position:relative;
	z-index: 0;
}
.BMLabel {
	font-size: 10px;
}
.Date {
	border-bottom: 1px solid #888 !important; 
}
.BMprogram:hover {
	color: #fff !important; 
    background-color: #888 !important; 
	cursor: pointer;
	
}
.puInfo { padding-left: 2em; z-index: 999; }
.BMtime {
	font-weight: normal;
	padding-left: 8pt;
	cursor: pointer;
}
.BMuserTime {
	color: #888;
	padding-left: 2pt;
}
.BMinfo {
	//background-color: #e0f5fd; 
}
.BMbreak {
	display: table-cell;
	//background-color: #e0f5fd;
	border-bottom: 1px solid #ccc; 
	font-weight: normal !important;
}
.notAvail {
	display: table-cell;
	border-bottom: 1px solid #ccc; 
	cursor: not-allowed  ;
}
.BMheadLine {
  //color: #fff !important;
  //background-color: #e0f5fd !important; 
}
.todrag {

}

.BMHeadActions {
	position: sticky !important;
	top: -.5px !important;
	margin-top: 0 !important;
	height: 21pt !important;
	z-index: 99;
 }
.BMHeadActions22 {
	 position: sticky !important;
	 top: 21pt !important;
	 z-index: 99;
 }

 .BMHeadActions:hover {
 	cursor: pointer;
 }
 .placementName {
	display: inline-flex;
	width: 40% !important;
	overflow-x: hidden;
    overflow-y: hidden;
	white-space: nowrap; /* Don't forget this one */
    text-overflow: ellipsis; 
 }
 .BMCounter {
	//background-color: #f2f2f2 !important; 
 } 
 .BMappHead { 
    width: 100%;  
    text-align: right;
    padding: 0pt 0pt 0pt;
 }
 .colored {
  background-color: #eef; 
 }
 .myButtonSmall {
 	//width: 18%;
     padding: 3px 4px;
     outline: none;
     border-radius: 4px 12px 0 0;
     height: 20pt;
     font-size: 7pt;
     background-color: #eef;
     border: 1px outset #aaa;
     color: rgb(0, 0, 0);
     margin-top: 4pt;
     margin-right: 0pt;
     margin-bottom: 4pt;
 } 
 .minRound {
	border-radius: 4px 4px 4px 4px;
 }
 .myButtonSmall:hover {
 	background-color: #bbb !important;
 }
 .myButtonSmall:where([active]) {
 	background-color: #bbf;
 	
 }
 .iButton {
    background-color: transparent;
    border: none;
    color: #363636;
    cursor: pointer;
    justify-content: center;
    padding-bottom: 2pt;;
    padding-left: 2pt;
    padding-right: 2pt;
    padding-top: 4pt;
    height: 20pt;
    text-align: center;
    white-space: nowrap;
}  
.iButton:hover {
    color: #fff !important;
  	background-color: rgb(88, 218, 218);; 
}  
img {
    max-width:80pt;
    height:auto;
} 
</style>
